require("normalize.css/normalize.css");
require("./styles/index.scss");

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import { TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Dragdealer from "dragdealer";

import { TimelineMax } from 'gsap';

const timeline = new TimelineMax();
timeline.to("#element", 1, {x: 100});


ScrollMagicPluginGsap(ScrollMagic, TweenMax);

var lazyLoadInstance = new LazyLoad();

$(window).on("load", function () {
    setTimeout(function () {
        $("body").addClass("loaded");
    }, 1000);
});

setTimeout(function () {
    $("body").addClass("loaded");
}, 30000);

$(".header-nav-handler").on("click", function (event) {
    event.preventDefault();
    $("body").toggleClass("nav-visible");
});

$(".header-nav a").on("click", function (event) {
    $("body").removeClass("nav-visible");
});

$(document).ready(function () {
    1000000 * 0.025 * 0.03 * 100;
    $("input.percent").on("change", function () {
        $(this).val(function (i, v) {
            console.log(v);
            v = parseInt(v.replace(/[^0-9]/g, ""));
            if (v > 100) v = "100";
            if (!v || v < 0.01) v = "0.01";
            return v + "%";
        });
    });
    $("input.dollar").on("change", function () {
        $(this).val(function (i, v) {
            v = parseInt(v.replace(/[^0-9]/g, ""));
            if (!v || v < 1) v = "1";
            return "$" + v;
        });
    });
    $("input.separated").on("change", function () {
        $(this).val(function (i, v) {
            v = parseInt(v.replace(/[^0-9]/g, ""));
            if (!v || v < 1) v = "1";
            v = v.toLocaleString("en-US");
            return v;
        });
    });
    $(".calc-form input").on("input", function () {
        var visitors = parseInt(
                $("#calc-visitors")
                    .val()
                    .replace(/[^0-9]/g, "")
            ),
            price = parseInt(
                $("#calc-price")
                    .val()
                    .replace(/[^0-9]/g, "")
            ),
            conversion = parseInt(
                $("#calc-conversion")
                    .val()
                    .replace(/[^0-9]/g, "")
            );

        if (conversion > 100) conversion = "100";
        if (!conversion || conversion < 0.01) conversion = "0.01";

        if (!price || price < 1) price = "1";

        if (!conversion || conversion < 1) conversion = "1";

        var result = visitors * price * 0.025 * (conversion / 100) * price;
        $("#calc-result").text(result.toLocaleString("en-US"));
    });
    $("#demo-request-form input").on("input", function () {
        $(".request-form-sent").removeClass("visible");
    });
    $("#demo-request-form input[name=email]").on("input", function () {
        var submitButton = $("#demo-request-form-submit");
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($(this).val())
        ) {
            submitButton.prop("disabled", false);
        } else {
            submitButton.prop("disabled", true);
        }
    });
});

$(document).on("click", 'a[href*="#"]:not([href="#"])', function (event) {
    event.preventDefault();

    if ($.attr(this, "href") == "#request" && $(window).innerWidth() < 900) {
        $("#demo-request-form").addClass("visible");
        return;
    }

    $("html, body").animate(
        {
            scrollTop: $($.attr(this, "href")).offset().top,
        },
        500
    );
});

$(document).on("click", ".request-form-close", function (event) {
    event.preventDefault();

    $("#demo-request-form").removeClass("visible");
});

$(document).on("click", ".secondary-marketplace", function (event) {
    event.preventDefault();

    $(this).toggleClass("jumping");
});

$(window).on("load resize", function (e) {
    $("video").each(function () {
        var isMobile = $(window).innerWidth() < 800;
        if (isMobile == true) {
            $(this).attr("poster", $(this).attr("mobile-poster"));
        } else {
            $(this).attr("poster", $(this).attr("desktop-poster"));
        }
        $(this)
            .find("source")
            .each(function () {
                if (isMobile == true) {
                    $(this).attr("src", $(this).attr("mobile-src"));
                } else {
                    $(this).attr("src", $(this).attr("desktop-src"));
                }
            });
        $(this)[0].load();
    });
});

$("#demo-request-form").on("submit", function (e) {
    e.preventDefault();

    var $form = $(this);
    $.post("/", $form.serialize()).then(function () {
        $form[0].reset();
        $("#demo-request-form-submit").prop("disabled", true);
        $(".request-form-sent").addClass("visible");
    });
});

function calculateVh(initial) {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
    if (initial == true)
        document.documentElement.style.setProperty("--ivh", vh + "px");
}

calculateVh(true);
window.addEventListener("resize", calculateVh);
window.addEventListener("orientationchange", calculateVh(true));

calculateVh(true);
window.addEventListener("resize", calculateVh);
window.addEventListener("orientationchange", calculateVh(true));

window.addEventListener("load", function () {
  if ($(window).width() > 1100) {
    var controller = new ScrollMagic.Controller();

    var tl = new TimelineMax();

    tl.to(
      "#how-step-1",
      1,
      {
        y: "75%",
      },
      0
    )
      .to(
        "#how-step-2",
        1,
        {
          autoAlpha: 1,
          y: "75%",
        },
        0
      )
      .to(
        "#how-step-2 .how-scene-steps-item-header-num",
        0.1,
        {
          scale: 1.3,
          x: "7px",
          backgroundColor: "white",
          color: "#000",
        },
        0.9
      )
      .to(
        "#how-step-2 .how-scene-steps-item-header-content",
        0.1,
        {
          x: "14px",
        },
        0.9
      )
      .to(
        "#how-step-1 .how-scene-steps-item-header-num",
        0.1,
        {
          scale: 1,
          x: 0,
          backgroundColor: "#616161",
          color: "#fff",
        },
        0.9
      )
      .to(
        "#how-step-1 .how-scene-steps-item-header-content",
        0.1,
        {
          x: 0,
        },
        0.9
      )
      .to(
        "#how-screen-1",
        0.1,
        {
          opacity: 0,
        },
        0.8
      )
      .to(
        "#how-screen-2",
        0.1,
        {
          opacity: 1,
        },
        0.9
      );

    tl.to(
      "#how-step-1",
      1,
      {
        y: "0%",
      },
      1
    )
      .to(
        "#how-step-2",
        1,
        {
          y: "0%",
        },
        1
      )
      .to(
        "#how-step-3",
        1,
        {
          autoAlpha: 1,
          y: "0%",
        },
        1
      )
      .to(
        "#how-step-3 .how-scene-steps-item-header-num",
        0.1,
        {
          scale: 1.3,
          x: "7px",
          backgroundColor: "white",
          color: "#000",
        },
        1.9
      )
      .to(
        "#how-step-3 .how-scene-steps-item-header-content",
        0.1,
        {
          x: "14px",
        },
        1.9
      )
      .to(
        "#how-step-3 .how-scene-steps-item-header-content-title-emoji",
        0.1,
        {
          x: 0,
          opacity: 1,
        },
        1.9
      )
      .to(
        "#how-step-2 .how-scene-steps-item-header-num",
        0.1,
        {
          scale: 1,
          x: 0,
          backgroundColor: "#616161",
          color: "#fff",
        },
        1.9
      )
      .to(
        "#how-step-2 .how-scene-steps-item-header-content",
        0.1,
        {
          x: 0,
        },
        1.9
      )
      .to(
        "#how-screen-2",
        0.1,
        {
          opacity: 0,
        },
        1.8
      )
      .to(
        "#how-screen-3",
        0.1,
        {
          opacity: 1,
        },
        1.9
      );

    var tl3 = new TimelineMax();

    tl3
      .to(
        "#own-image-2",
        1,
        {
          scale: 1,
        },
        0
      )
      .to(
        "#own-image-2",
        1,
        {
          boxShadow: "0px 0px 18px rgba(47, 44, 44, 0.7)",
        },
        0
      )
      .to(
        "#own-image-3",
        1,
        {
          left: "40%",
          top: "12.5%",
          rotation: "6",
        },
        0
      )
      .to(
        "#own-image-1",
        1,
        {
          left: "-40%",
          top: "12.5%",
          rotation: "-6",
        },
        0
      );

    new ScrollMagic.Scene({
      triggerElement: "#how",
      triggerHook: 0.5,
      offset: 580,
      duration: "100%",
    })
      .setPin("#how")
      .setTween(tl)
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: "#own",
      triggerHook: 1,
      offset: 345,
      duration: "50%",
    })
      //.setPin("#own")
      .setTween(tl3)
      .addTo(controller);
  }
});

$(window).on("load", function () {
  if ($(window).width() > 1100) {
    $("#stats-slider").addClass("dragdealer");
    const statsSlider = new Dragdealer("stats-slider", {
      animationCallback(x) {
        var runner = $(".celebrating-stats-bar-runner"),
          runnerPos = (runner.parent().innerWidth() - runner.outerWidth()) * x;
        runner.css("transform", "translateX(" + runnerPos + "px)");
      },
    });
  }
  });
